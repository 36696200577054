<template>
  <div>
    <div class="deviceTypes-operations">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:device_type:create'])"
          type="primary"
          class="add-btn"
          @click="jumpToCreator()"
          >新增</a-button
        >
      </div>
      <div class="data-filter" v-if="filterOptions">
        <a-select
          v-if="filterSelected"
          :default-value="filterSelected.value"
          style="width: 120px"
          @change="hangleChange"
        >
          <a-icon slot="suffixIcon" type="smile" />
          <a-select-option v-for="provider in filterOptions" :key="provider.value">{{
            provider.label
          }}</a-select-option>
        </a-select>
        <a-input
          class="search-keyword"
          v-model="searchKeyword"
          clearable
          placeholder
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      :pagination="false"
      :columns="this.$constRepository.deviceType.columns"
      :data-source="deviceTypeList"
      class="deviceTypes-table"
      rowKey="deviceTypeId"
    >
      <span slot="customTitle"> <a-icon type="smile-o" />Name </span>
      <span slot="tags" slot-scope="tags">
        <a-tag
          v-for="tag in tags"
          :key="tag"
          :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
          >{{ tag.toUpperCase() }}</a-tag
        >
      </span>
      <span slot="action" slot-scope="scope">
        <a-button class="green-btn" ghost @click="jumptoTemplate(scope)">模板</a-button>
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:device_type:update'])" class="green-btn" ghost @click="jumptoEdit(scope)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:i18n:list'])" class="green-btn" ghost @click="jumptoI18n(scope)"
          >国际化</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:device_type:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除设备类型?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceTypeList',
  components: {},
  async activated() {
    await this.loadedDeviceType();
    this.loadedPageData();
  },
  data() {
    return {
      filterSelected: this.$constRepository.deviceType.FILTER_OPTION_DEVICETYPE_NAME,
      filterOptions: [
        this.$constRepository.deviceType.FILTER_OPTION_DEVICETYPE_NAME,
        this.$constRepository.deviceType.FILTER_OPTION_DEVICETYPE_ENNAME,
      ],
      searchKeyword: '',
      requestParams: {},
      deviceTypeList: undefined,
      loadedDeviceTypeList: undefined,
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    hangleChange(value) {
      this.filterOptions.forEach((brand) => {
        if (brand.value === value) {
          this.filterSelected = brand;
        }
      });
    },
    async loadedDeviceType() {
      const response = await this.$apiManager.deviceType.loadedDeviceType({ unpaged: true });
      this.loadedDeviceTypeList = response.data.elements;
    },
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'enName', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.deviceType.FILTER_OPTION_DEVICETYPE_NAME:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.deviceType.FILTER_OPTION_DEVICETYPE_ENNAME:
          this.$set(this.requestParams, 'enName', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async listDeviceType(Name, enName, queryPage) {
      const params = {
        name: Name,
        enName,
        page: queryPage.page - 1,
        size: queryPage.size,
      };
      const response = await this.$apiManager.deviceType.getDeviceTypes(params);
      this.deviceTypeList = response.data.elements;
      this.deviceTypeList.forEach((loaded) => {
        this.$set(
          loaded,
          'loadedDeviceTypeName',
          this.loadedDeviceTypeList.find((item) => loaded.loadDeviceTypeId === item.loadedDeviceTypeId.toString()).name,
        );
      });
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listDeviceType(this.requestParams.name, this.requestParams.enName, this.pageInfo);
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listDeviceType(this.requestParams.name, this.requestParams.enName, this.pageInfo);
      }
    },
    async onDelete(deviceTypes) {
      await this.$apiManager.deviceType.deleteDeviceTypes(deviceTypes.deviceTypeId);
      if (this.deviceTypeList.length === 1 && this.pageInfo.page !== 1) {
        this.pageInfo.page -= 1;
      }
      this.loadedPageData();
    },
    jumptoEdit(info) {
      this.$router.push({ name: 'deviceTypeEdit', params: { deviceType: info } });
    },
    jumptoI18n(info) {
      this.$router.push({ name: 'deviceTypeI18n', params: { deviceType: info } });
    },
    jumptoTemplate(info) {
      this.$router.push({ name: 'templateEdit', params: { deviceType: info } });
    },
    jumpToCreator() {
      this.$router.push({ name: 'deviceTypeCreator' });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.deviceTypes-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .deviceTypes-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
</style>
